import { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Box, InputLabel } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import React, { memo } from "react";
import { useProductsContext } from "../../Contexts/SidebarContext";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { NetworkManager } from "../../Components/NetworkManager";
import { CommonUtil } from "../../utils/CommonUtil";
import { useNavigate } from "react-router-dom";
import ToastManager from "../../Components/ToastManager";
interface pubDialogue {
  show: boolean;
  onCancelDialogue: Function;
  onSaveTemplateName: Function;
}

const btnStyle = { marginTop: 10 };
function PublishDialog({
  show,
  onCancelDialogue,
  onSaveTemplateName,
}: pubDialogue) {
  const { pubError, templateInfo, pageInfo } = useProductsContext();

  const initialValues = {
    templateName:
      templateInfo.templateName !== null && templateInfo.templateName !== ""
        ? templateInfo.templateName
        : "",
    checked:
      templateInfo.templateType !== null &&
      templateInfo.templateType !== undefined &&
      templateInfo.templateType == "default"
        ? true
        : false,
  };
  const validationSchema = Yup.object().shape({
    templateName: Yup.string()
      .min(3, "It's too short")
      .required("Please Enter Template Name"),
  });

  const [open, setOpen] = useState(false);
  const [showDefaultField, setShowDefaultField] = useState(false);
  const [templateId, setTemplateId] = useState("");
  const [templateDetails, setTemplateDetails] = useState({
    templateId: "",
    templateName: "",
  });
  const navigate = useNavigate();

  useEffect(() => {
    setOpen(show);
  }, [show]);
  const handleClose = () => {
    onCancelDialogue();
  };

  const onHandleSave = (value: any) => {
    onSaveTemplateName(value.templateName, value.checked, templateId);
    setShowDefaultField(false);
    setTemplateId("");
    // navigate("/dashboard/templates");
  };
  const onSuccessCall = (data: any) => {
    var respData = CommonUtil.parseData(data);

    if (respData) {
      if (respData.statusCode === "Template2000") {
        if (respData.hasDefault) {
          setTemplateId(respData.templateId);
          setTemplateDetails({
            ...templateDetails,
            templateId: respData.templateId,
            templateName: respData.templateName,
          });
          setShowDefaultField(true);
        }
      }
    }
  };

  const onErrorCall = (err: any) => {
    ToastManager.showToast(
      "Error Occured while setting the template to Specific ",
      "Error"
    );
  };
  const onDefaultClick = (event: any) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      const req = {
        pageId: pageInfo.pageId,
      };

      try {
        NetworkManager.sendJsonRequest(
          "template/checkForDefaultTemplate",
          req,
          "",
          "POST",
          true,
          onSuccessCall,
          onErrorCall
        );
      } catch (error) {}
    } else {
      setShowDefaultField(false);
      setTemplateId("");
    }
  };

  const onSuccessCallResetTemp = (data: any) => {
    var respData = CommonUtil.parseData(data);
    if (respData) {
      if (respData.statusCode === "Template2000") {
        //Do nothing
        ToastManager.showToast(
          "Current template is set from Default to Specific ",
          "Success"
        );
      }
    }
  };
  const reSetDefault = () => {
    const req = {
      pageId: pageInfo.pageId,
      templateId: templateId,
    };

    try {
      NetworkManager.sendJsonRequest(
        "template/setCurrentDefaultToSpecific",
        req,
        "",
        "POST",
        true,
        onSuccessCallResetTemp,
        onErrorCall
      );
    } catch (error) {}
  };
  const onSubmit = (values: any, props: any) => {
    onHandleSave(values);
    props.resetForm();
  };

  return (
    <div>
      <Dialog
        open={open}
        PaperProps={{
          sx: {
            width: "80%",
            maxHeight: 600,
          },
        }}
      >
        <DialogTitle>Template Information</DialogTitle>
        <DialogContent>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {(props) => (
              <Form noValidate>
                {/* <label htmlFor="cardName" style={{ display: "block" }}>
                  Template Name
                </label> */}
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: 0.5,
                    marginTop: "0.5vh",
                    marginBottom: "2vh",
                  }}
                >
                  <Field
                    as={Checkbox}
                    label="Default?"
                    type="checkbox"
                    name="checked"
                    onClick={(e: any) => onDefaultClick(e)}
                  />
                  <InputLabel sx={{ marginTop: "1.5vh" }}>
                    Default Template?
                  </InputLabel>
                </Box>

                {showDefaultField ? (
                  <div>
                    <InputLabel sx={{ marginTop: "1.5vh" }}>
                      {`There's already a default Template "${templateDetails.templateName}" attached to the page.`}
                    </InputLabel>

                    <InputLabel>
                      Do you want to replace it with the current one?
                    </InputLabel>
                    <Button
                      type="button"
                      sx={{
                        marginBottom: "10px",
                        backgroundColor: "lightblue",
                        borderRadius: "30%",
                      }}
                      onClick={reSetDefault}
                    >
                      Yes
                    </Button>
                  </div>
                ) : (
                  ""
                )}

                <Field
                  as={TextField}
                  name="templateName"
                  label="Template Name"
                  fullWidth
                  error={
                    props.errors.templateName && props.touched.templateName
                  }
                  disabled={
                    templateInfo.templateName !== null &&
                    templateInfo.templateName !== undefined
                      ? true
                      : false
                  }
                  helpertext={<ErrorMessage name="templateName" />}
                  required
                  value={props.values.templateName}
                />
                <p>{pubError}</p>
                <Button type="submit" style={btnStyle}>
                  Save
                </Button>
                <Button
                  style={btnStyle}
                  onClick={handleClose}
                  disabled={templateId ? true : false}
                >
                  Cancel
                </Button>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </div>
  );
}
export default memo(PublishDialog);

//onClick={onSubmit}
