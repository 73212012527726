import * as React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import { visuallyHidden } from "@mui/utils";
import { NetworkManager } from "../../Components/NetworkManager";
import { CommonUtil } from "../../utils/CommonUtil";
import { useProductsContext } from "../../Contexts/SidebarContext";
import { extractNodesEdges } from "../chatbottool/publishTemplate";
import PostConnectDialog from "../../Components/ConnectPost/ConnectToPostDialog";
import SpecificPostInfo from "../../Components/specificpostview/ViewSpecificPostDialog";
import { Grid, Stack } from "@mui/material";
import ToastManager from "../../Components/ToastManager";
import PauseCircleOutlineIcon from "@mui/icons-material/PauseCircleOutline";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import UnpauseFbDialog from "./UnpauseFbDialog";
interface Data {
  templateName: string;
  // status: number;
  connectToPost: string;
  templateType: string;
  pauseType: string;
  // name: string;
  // protein: number;
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  //const stabilizedThis = array.slice().sort(comparator);
  return stabilizedThis.map((el: any) => el[0]);
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: "templateName",
    numeric: false,
    disablePadding: true,
    label: "Template Name",
  },
  // {
  //   id: "status",
  //   numeric: false,
  //   disablePadding: false,
  //   label: "Status",
  // },
  {
    id: "connectToPost",
    numeric: false,
    disablePadding: false,
    label: "Connect To Post",
  },
  {
    id: "templateType",
    numeric: false,
    disablePadding: false,
    label: "Template Type",
  },
  {
    id: "pauseType",
    numeric: false,
    disablePadding: false,
    label: "",
  },
];

interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const {
    onSelectAllClick,
    //  order,
    //  orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler =
    (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          {/* <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          /> */}
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            // sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
            // active={orderBy === headCell.id}
            // direction={orderBy === headCell.id ? order : "asc"}
            // onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {/* {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null} */}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

interface EnhancedTableToolbarProps {
  numSelected: number;
  title: string;
}

function EnhancedTableToolbar(props: EnhancedTableToolbarProps) {
  const { numSelected } = props;
  const { title } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        minHeight: "53px !important",
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          {title}
        </Typography>
      )}
      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
}

export default function EnhancedTable({
  rows,
  title,
  setTemplateList,
  from,
}: any) {
  //users: Array
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<keyof Data>("templateName");
  const [selected, setSelected] = React.useState<readonly string[]>([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [postDialog, setPostDialog] = React.useState(false);
  const [specificDialog, setSpecificDialog] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [templateInfo, setTemplateInfo] = React.useState({});
  const [unpauseFbDialog, setUnpauseFbDialog] = React.useState(false);
  const {
    saveNewNodeAndEdges,
    saveTemplates,
    saveTemplateName,
    savePageIdAndName,
  } = useProductsContext();
  // const rows = [{ ...template }];
  let pageId: string = "";
  let fbPageId: string = "";
  let pageName: any = null;
  let fbAccountId: any = null;

  const navigate = useNavigate();
  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = rows.map((n: any) => n.name);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: readonly string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDense(event.target.checked);
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = React.useMemo(
    () =>
      stableSort(rows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [order, orderBy, page, rowsPerPage, rows]
  );
  // const visibleRows = rows;
  const onErrorCall = (err: any) => {};
  const selectedConnection: any = localStorage.getItem("activeConnection");

  const onTemplateNameClick = (e: any, templateRow: any) => {
    let getTemplateDataReq = {
      templateName: templateRow.templateName,
      pageId: templateRow.pageId,
    };
    saveTemplateName(templateRow);
    localStorage.setItem("selectedConnection", "nav-fb-tab");
    try {
      NetworkManager.sendJsonRequest(
        "template/getTemplateData",
        getTemplateDataReq,
        "",
        "POST",
        true,
        onSuccessCall,
        onErrorCall
      );
    } catch (error) {}
  };

  const onSuccessCall = (data: any) => {
    var respData = CommonUtil.parseData(data);

    if (respData) {
      saveTemplates(respData.templateData);
      saveNewNodeAndEdges([], []);
      const result = extractNodesEdges(respData.templateData);
      const { nodes, edges } = result;

      saveNewNodeAndEdges(nodes, edges);
      navigate(`/dashboard/chatbot`, { state: { create: false } });
    }
  };

  const onConnToPostClick = (e: any, templateRow: any) => {
    if (templateRow.templateType === "default") {
      // do nothing
    } else {
      onTemplateNameSave(templateRow);
      setPostDialog(true);
    }
  };
  const onClickSpecificTemplate = (e: any, templateRow: any) => {
    if (templateRow.templateType === "default") {
      // do nothing
    } else {
      onTemplateNameSave(templateRow);
      setSpecificDialog(true);
    }
  };
  const onTemplateNameSave = async (template: any) => {
    setTemplateInfo({
      templateName: template.templateName,
      templateId: template.templateId,
      templateType: template.templateType,
    });
  };
  const playDialogTemplate = (templateRow: any) => {
    console.log(templateRow);
    onTemplateNameSave(templateRow);
    setUnpauseFbDialog(true);
  };
  const onCancelDialogue = (data: any) => {
    if (data === "specific") setSpecificDialog(false);
    else setPostDialog(false);
    setUnpauseFbDialog(false);
  };
  const onSuccessCallPause = () => {
    getAllFbTemplates();
    ToastManager.showToast("Template is Successfully Paused", "Success");
    savePageIdAndName(pageId, pageName, null, fbAccountId);
  };
  const onSuccessCallFb = (data: any) => {
    var respData = CommonUtil.parseData(data);
    if (respData) {
      if (respData.statusCode === "Template2000") {
        //setTemplateList(respData.templateDetails);
        const tempalteDetails = respData.templateDetails;
        setTemplateList(tempalteDetails);
      }
    }
  };
  const onErrorCallFb = () => {};

  const getAllFbTemplates = () => {
    const fbPageInfo: any = localStorage.getItem("fbPageDetail");
    const recFbPageInfp = JSON.parse(fbPageInfo)?.facebook;

    pageId = recFbPageInfp?.fbPageId;
    pageName = recFbPageInfp?.pageName;
    fbAccountId = recFbPageInfp?.fbAccountId;
    if (pageId) {
      console.log(pageId);

      let pageDataReq = {
        pageId: pageId,
      };

      try {
        NetworkManager.sendJsonRequest(
          "template/getTemplatesByPageId",
          pageDataReq,
          "",
          "POST",
          true,
          onSuccessCallFb,
          onErrorCallFb
        );
      } catch (error) {}
      savePageIdAndName(pageId, pageName, fbAccountId);
    }
  };

  const onErrorCallPause = (err: any) => {
    ToastManager.showToast(
      "Error Occured while setting the template to Pause",
      "Error"
    );
  };

  const handleChangePause = (
    event: React.ChangeEvent<HTMLInputElement>,
    templateRow: any
  ) => {
    const fbPageInfo: any = localStorage.getItem("fbPageDetail");
    const recFbPageInfp = JSON.parse(fbPageInfo)?.facebook;
    console.log(recFbPageInfp);

    // const isPaused = event.target.checked;

    const req = `templateId=${templateRow.templateId}&pageId=${recFbPageInfp.fbPageId}&fbAccountId=${recFbPageInfp.fbAccountId}`;

    try {
      NetworkManager.sendJsonRequest(
        "template/pauseTemplate",
        "",
        req,
        "POST",
        true,
        onSuccessCallPause,
        onErrorCallPause
      );
    } catch (err: any) {}
  };

  const label = { inputProps: { "aria-label": "Switch demo" } };
  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Paper sx={{ width: "100%", mb: 2 }}>
          <EnhancedTableToolbar numSelected={selected.length} title={title} />
          <hr style={{ margin: 0 }} />
          <TableContainer sx={{ height: 330 }} className="custom-scrollbar-css">
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={dense ? "small" : "medium"}
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
              />
              <TableBody>
                {visibleRows.map((row: any, index: any) => {
                  const isItemSelected = isSelected(row.templateName);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      // onClick={(event) => handleClick(event, row.templateName)}
                      // role="checkbox"
                      // aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.templateName}
                      selected={isItemSelected}
                      // sx={{ cursor: "pointer" }}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          onClick={(event) =>
                            handleClick(event, row.templateName)
                          }
                          color="primary"
                          role="checkbox"
                          checked={isItemSelected}
                          inputProps={{
                            "aria-labelledby": labelId,
                          }}
                        />
                      </TableCell>

                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                        sx={{ cursor: "pointer" }}
                        onClick={(e) => onTemplateNameClick(e, row)} //onConnToPostClick
                      >
                        {row.templateName}
                      </TableCell>
                      {/* <TableCell align="left">
                        <Switch checked={true} />
                      </TableCell> */}
                      {/* <TableCell align="left">{row.templateName}</TableCell> <Switch checked={dense} onChange={handleChangeDense} /> */}
                      <TableCell
                        align="left"
                        sx={{
                          cursor: `${
                            row.templateType === "default"
                              ? "not-allowed"
                              : "pointer"
                          }`,
                        }}
                        onClick={(e) => onConnToPostClick(e, row)}
                      >
                        {row.templateName}
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          cursor: `${
                            row.templateType === "default"
                              ? "not-allowed"
                              : "pointer"
                          }`,
                        }}
                        onClick={(e) => onClickSpecificTemplate(e, row)}
                      >{`${
                        row.templateType === "default"
                          ? row.templateType === "specific"
                            ? "Specific"
                            : "Default"
                          : "View/Set Specific Post?"
                      }`}</TableCell>
                      <TableCell
                        // onClick={(e: any) => onClickPause(e)}
                        onClick={(event: any) =>
                          row.templateType === "paused"
                            ? playDialogTemplate(row)
                            : handleChangePause(event, row)
                        }
                      >
                        {row.templateType !== "paused" ? (
                          <PauseCircleOutlineIcon fontSize="large" />
                        ) : (
                          <PlayCircleIcon fontSize="large" />
                        )}
                        {/* </Grid> */}
                      </TableCell>
                    </TableRow>
                  );
                })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (dense ? 33 : 53) * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
      <PostConnectDialog
        isOpen={postDialog}
        onCancelDialogue={onCancelDialogue}
        templateInfo={templateInfo}
      />
      <SpecificPostInfo
        isOpen={specificDialog}
        onCancelDialogue={onCancelDialogue}
        templateInfo={templateInfo}
      />
      <UnpauseFbDialog
        isPauseOpen={unpauseFbDialog}
        onCancelDialogue={onCancelDialogue}
        templateInfo={templateInfo}
        setUnpauseFbDialog={(flag: boolean) => {
          setUnpauseFbDialog(false);
          getAllFbTemplates();
        }}
      />
    </>
  );
}
