import { useEffect, useState } from "react";

import { useNavigate, useLocation } from "react-router-dom";
import { useProductsContext } from "../../Contexts/SidebarContext";
import { CommonUtil } from "../../utils/CommonUtil";
import { NetworkManager } from "../../Components/NetworkManager";
import InstaTempTableData from "./InstaTempTableData";
import CustomizedBreadcrumbs from "../../Components/Breadcrum";
import { Tab, Tabs } from "@mui/material";
import LiveInstaTempTableData from "./LiveInstaTemplates";
import ToastManager from "../../Components/ToastManager";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  tabStyle: {
    textTransform: "none",
    fontSize: "16px",
    fontWeight: 600,
    padding: "6px 20px !important",
    minHeight: "35px !important",
    width: "190px !important",
    marginTop: "8px !important",
  },
}));

export default function BasicTable() {
  let pageId: string = "";
  let pageName: any = null;
  let instaId: any = null;
  const [iTemplateList, setITemplateList] = useState<string[]>([]);
  const [iLiveTemplateList, setILiveTemplateList] = useState([]);
  const [message, setMessage] = useState("Insta Templates");
  const [selectedTab, setSelectedTab] = useState(0);
  const { sideBar, savePageIdAndName, setLiveTemp } = useProductsContext();
  const classes = useStyles();

  // ------------- Instagram -----------

  useEffect(() => {
    getAllInstaTemplates();
  }, []);
  useEffect(() => {
    if (selectedTab === 1) {
      getAllInstaLiveTemplates();
    }
  }, [selectedTab]);

  const getAllInstaTemplates = () => {
    const fbPageInfo: any = localStorage.getItem("fbPageDetail");
    const recFbPageInfp = JSON.parse(fbPageInfo)?.instagram;

    pageId = recFbPageInfp?.pageId;
    pageName = recFbPageInfp?.instaName;
    instaId = recFbPageInfp?.instaId;
    let params: any = "";
    if (pageId) {
      params = `pageId=${pageId}`;
      try {
        NetworkManager.sendJsonRequest(
          "api/instagram/getTemplatesOfInstaId",
          "",
          params,
          "GET",
          true,
          onSuccessCallInsta,
          onErrorCall
        );
      } catch (error) {}
      savePageIdAndName(pageId, pageName, null, instaId);
    }
  };
  const getAllInstaLiveTemplates = () => {
    const fbPageInfo: any = localStorage.getItem("fbPageDetail");
    const recFbPageInfp = JSON.parse(fbPageInfo)?.instagram;
    pageId = recFbPageInfp?.pageId;
    pageName = recFbPageInfp?.instaName;
    instaId = recFbPageInfp?.instaId;
    let params: any = "";
    if (pageId) {
      params = `instaId=${instaId}`;
      try {
        NetworkManager.sendJsonRequest(
          "instagram/live/template/getLiveTemplatesByInstaId",
          "",
          params,
          "GET",
          true,
          onSuccessCallInstaLive,
          onErrorCallLive
        );
      } catch (error) {}
      savePageIdAndName(pageId, pageName, null, instaId);
    }
  };

  const onSuccessCallInsta = (data: any) => {
    var respData = CommonUtil.parseData(data);
    if (respData) {
      const iTemplateDetails = respData;
      setITemplateList(iTemplateDetails);
    }
  };
  const onErrorCall = (err: any) => {};
  const onSuccessCallInstaLive = (data: any) => {
    var respData = CommonUtil.parseData(data);
    if (respData) {
      const iLiveTemplateDetails = respData.liveTemplateDetailsList;
      setILiveTemplateList(iLiveTemplateDetails);
    }
    if (respData.statusCode === "Insta1019") {
      ToastManager.showToast("No Live templates for the instaId", "Error");
    }
  };
  const onErrorCallLive = (err: any) => {};
  const navigate = useNavigate();
  const handleMenuInsta = () => {
    setLiveTemp("mediatemplates");
    navigate(`/dashboard/chatbot`, {
      state: { create: true, from: "insta" },
    });
  };
  const handleMenuInstaLive = () => {
    setLiveTemp("livetemplates");
    navigate(`/dashboard/chatbot`, {
      state: { create: true, from: "insta" },
    });
  };
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  return (
    <div
      className="content-page"
      style={{
        marginLeft: sideBar ? "230px" : "120px",
        backgroundColor: "#fff",
      }}
    >
      <div className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="page-title-box">
                <div className="page-title-right">
                  <div className="breadcrumb m-0">
                    <CustomizedBreadcrumbs message={message} />
                  </div>
                </div>
                <h4 className="page-title">Chatbot Tool</h4>
              </div>
            </div>
          </div>
          <div className="sec-wrapper">
            <div className="d-flex align-items-center justify-content-between mb-2">
              <div>
                <Tabs
                  value={selectedTab}
                  onChange={handleTabChange}
                  aria-label="tabs"
                  variant="fullWidth"
                  sx={{
                    "& .Mui-selected": {
                      bgcolor: "#4aa5dc",
                      color: "#ffffff !important",
                      borderRadius: "50rem",
                    },
                    "& .MuiTab-root:not(.Mui-selected)": {
                      border: "1px solid #ccc",
                      borderColor: "#ccc",
                      borderRadius: "50rem !important",
                    },
                    "& .MuiTabs-indicator": {
                      width: "0px !important",
                    },
                  }}
                >
                  <Tab
                    iconPosition="start"
                    label="Media Templates"
                    className={classes.tabStyle}
                  />
                  <Tab
                    iconPosition="start"
                    label="Live Templates"
                    className={classes.tabStyle}
                  />
                </Tabs>
              </div>
              <div className="text-end">
                {selectedTab === 0 ? (
                  <button
                    type="button"
                    onClick={handleMenuInsta}
                    className="btn btn-primary rounded-pill new-chat"
                  >
                    <img
                      src="../assets/images/chatbot.svg"
                      className="img-fluid"
                      alt=""
                    />
                    Insta ChatBot Tool
                  </button>
                ) : (
                  <button
                    type="button"
                    onClick={handleMenuInstaLive}
                    className="btn btn-primary rounded-pill new-chat"
                  >
                    <img
                      src="../assets/images/chatbot.svg"
                      className="img-fluid"
                      alt=""
                    />
                    Live Insta ChatBot Tool
                  </button>
                )}
              </div>
            </div>
            {selectedTab === 0 && (
              <div className="tablerounededCorner table-responsive">
                <InstaTempTableData
                  rows={iTemplateList}
                  title="Insta Templates List"
                  setITemplateList={setITemplateList}
                />
              </div>
            )}
            {selectedTab === 1 && (
              <div className="tablerounededCorner table-responsive">
                <LiveInstaTempTableData
                  rows={iLiveTemplateList}
                  title=" Live Insta Templates List"
                  setILiveTemplateList={setILiveTemplateList}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
