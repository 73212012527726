import { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Box, Grid, InputLabel } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import React, { memo } from "react";
import { useProductsContext } from "../../Contexts/SidebarContext";
import { NetworkManager } from "../../Components/NetworkManager";
import { CommonUtil } from "../../utils/CommonUtil";
import { useNavigate } from "react-router-dom";
import ToastManager from "../../Components/ToastManager";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";

const btnStyle = { marginTop: 10 };
let pageId: string = "";
let pageName: any = null;
let fbAccountId: any = null;

const UnpauseFbDialog = ({
  isPauseOpen,
  onCancelDialogue,
  templateInfo,
  setTemplateList,
  onSaveTemplateName,
  setUnpauseFbDialog,
}: any) => {
  const { savePageIdAndName } = useProductsContext();

  const initialValues = {
    // checked:
    //   templateInfo.templateType !== null &&
    //   templateInfo.templateType !== undefined &&
    //   templateInfo.templateType == "default"
    //     ? true
    //     : false,
  };
  const validationSchema = Yup.object().shape({});

  const [open, setOpen] = useState<any>(false);
  const [showDefaultField, setShowDefaultField] = useState(false);
  const [templateId, setTemplateId] = useState("");
  const [checked, setChecked] = useState(false);
  const [defChecked, setDefChecked] = useState(false);
  const [openSpecific, setOpenSpecific] = useState(true);
  const [templateDetails, setTemplateDetails] = useState({
    templateId: "",
    templateName: "",
  });
  const { pubError, pageInfo } = useProductsContext();

  const onSuccessCall = (data: any) => {
    var respData = CommonUtil.parseData(data);
    console.log(respData);

    if (respData) {
      if (respData.statusCode === "Template2000") {
        if (respData.hasDefault) {
          setTemplateId(respData.templateId);
          setTemplateDetails({
            ...templateDetails,
            templateId: respData.templateId,
            templateName: respData.templateName,
          });
          setShowDefaultField(true);
        }
      }
    }
  };

  const onErrorCall = (err: any) => {
    ToastManager.showToast(
      "Error Occured while setting the template to Specific ",
      "Error"
    );
  };

  const onDefaultClick = (event: any) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      const req = {
        pageId: pageInfo.pageId,
      };

      try {
        NetworkManager.sendJsonRequest(
          "template/checkForDefaultTemplate",
          req,
          "",
          "POST",
          true,
          onSuccessCall,
          onErrorCall
        );
      } catch (error) {}
    } else {
      setShowDefaultField(false);
      setTemplateId("");
    }
    if (event.target.checked) {
      setDefChecked(true);
      setChecked(false);
    } else {
      setDefChecked(false);
    }
  };

  const onSuccessCallResetTemp = (data: any) => {
    var respData = CommonUtil.parseData(data);
    if (respData) {
      if (respData.statusCode === "Template2000") {
        //Do nothing
        ToastManager.showToast(
          "Current template is set from Default to Specific",
          "Success"
        );
      }
    }
  };

  const onErrorCallSpe = () => {};

  const reSetDefault = (templateRow: any) => {
    const req = {
      pageId: pageInfo.pageId,
      templateId: templateId,
    };

    try {
      NetworkManager.sendJsonRequest(
        "template/setCurrentDefaultToSpecific",
        req,
        "",
        "POST",
        true,
        onSuccessCallResetTemp,
        onErrorCallSpe
      );
    } catch (error) {
      ToastManager.showToast(
        "Error Occured while setting the template to Default ",
        "Error"
      );
    }
  };

  useEffect(() => {
    setOpen(isPauseOpen);
    if (isPauseOpen) {
      setOpenSpecific(true);
      setChecked(false);
      setDefChecked(false);
    }
  }, [isPauseOpen]);

  const handleClose = () => {
    onCancelDialogue();
  };
  const navigate = useNavigate();

  const onSuccessCallFb = (data: any) => {
    var respData = CommonUtil.parseData(data);
    if (respData) {
      if (respData.statusCode === "Template2000") {
        //setTemplateList(respData.templateDetails);
        const tempalteDetails = respData.templateDetails;
        setTemplateList(tempalteDetails);
      }
    }
  };
  const onErrorCallPageId = () => {};

  const onSuccessCallUnpause = (data: any) => {
    var respData = CommonUtil.parseData(data);
    if (respData.statusCode === "Template2000") {
      ToastManager.showToast("Template is Successfully Unpaused", "Success");
    } else if (respData.statusCode === "Template3000") {
      ToastManager.showToast(
        "Error Occured while setting the template to Specific ",
        "Error"
      );
    }
    setUnpauseFbDialog(false);
    setShowDefaultField(false);
  };
  const onErrorCallUnpause = () => {};

  const onSubmit = (values: any) => {
    const fbPageInfo: any = localStorage.getItem("fbPageDetail");
    const recFbPageInfp = JSON.parse(fbPageInfo)?.facebook;
    console.log(recFbPageInfp);

    const req = `templateId=${templateInfo.templateId}&pageId=${
      recFbPageInfp.fbPageId
    }&fbAccountId=${recFbPageInfp.fbAccountId}&setTemplateTypeTo=${
      defChecked ? "default" : "specific"
    }&previousDefaultTemplateId=${templateId}`;

    try {
      NetworkManager.sendJsonRequest(
        "template/unpauseTemplate",
        "",
        req,
        "POST",
        true,
        onSuccessCallUnpause,
        onErrorCallUnpause
      );
    } catch (err: any) {}
  };

  return (
    <div>
      <Dialog
        open={open}
        PaperProps={{
          sx: {
            width: "80%",
            maxHeight: 600,
          },
        }}
      >
        <DialogTitle>Template Selection</DialogTitle>
        <DialogContent>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {(props) => (
              <Form noValidate>
                {/* <label htmlFor="cardName" style={{ display: "block" }}>
                  Template Name
                </label> */}
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: 0.5,
                    marginTop: "0.5vh",
                    marginBottom: "2vh",
                  }}
                >
                  <Field
                    as={Checkbox}
                    label="Default?"
                    type="checkbox"
                    name="checked"
                    // checked={defChecked}
                    onClick={onDefaultClick}
                  />
                  <InputLabel sx={{ marginTop: "1.5vh" }}>
                    Default Template?
                  </InputLabel>
                </Box>
                {showDefaultField ? (
                  <div>
                    <InputLabel sx={{ marginTop: "1.5vh" }}>
                      {`There's already a default Template "${templateDetails.templateName}" attached to the page.`}
                    </InputLabel>

                    <InputLabel>
                      Do you want to replace it with the current one?
                    </InputLabel>
                    <Button
                      type="button"
                      sx={{
                        marginBottom: "10px",
                        backgroundColor: "lightblue",
                        borderRadius: "30%",
                      }}
                      onClick={(row: any) => {
                        reSetDefault(row);
                        setOpenSpecific(false);
                        setShowDefaultField(false);
                      }}
                    >
                      Yes
                    </Button>
                  </div>
                ) : (
                  ""
                )}

                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: 0.5,
                    marginTop: "0.5vh",
                    marginBottom: "2vh",
                  }}
                >
                  {openSpecific ? (
                    <Field
                      as={Checkbox}
                      label="Specific?"
                      type="checkbox"
                      name="specificChecked"
                      checked={checked}
                      onChange={(e: any) => {
                        if (e.target.checked) {
                          setChecked(true);
                          setDefChecked(false);
                        } else {
                          setChecked(false);
                        }
                      }}
                    />
                  ) : (
                    ""
                  )}
                  <InputLabel sx={{ marginTop: "1.5vh" }}>
                    Specific Template?
                  </InputLabel>
                </Box>

                <Button
                  onClick={(value: any) => onSubmit(value)}
                  style={btnStyle}
                >
                  Save
                </Button>
                <Button
                  style={btnStyle}
                  onClick={handleClose}
                  disabled={defChecked ? true : false}
                >
                  Cancel
                </Button>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default UnpauseFbDialog;
