import React, { useEffect } from "react";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import chatIcon from "./../../images/chat-icon.png";
import InstaChatIcon from "./../../images/Insta-icon.png";
import { useProductsContext } from "../../Contexts/SidebarContext";

const SelIcon = () => {
  const { subscriber } = useProductsContext();
  const selectedConnection: any = localStorage.getItem("selectedConnection");

  const getIcon = () => {
    if (subscriber === "subscriber") {
      return <img alt="" src={chatIcon} width={23} height={23} />;
    }
    if (selectedConnection === "nav-inst-tab") {
      return (
        <InstagramIcon
          sx={{ width: "32px", height: "32px" }}
          color="secondary"
        />
      );
    }
    return <img alt="" src={chatIcon} width={23} height={23} />;
  };

  return getIcon();
};

export default SelIcon;
